import React from "react"
import styled from "styled-components"

const SectionElement = styled.section`
  padding: var(--gutter-l) var(--gutter-s);

  @media (${prop => prop.theme.screenSizes.md}) {
    padding: var(--gutter-m);
  }
  @media (${prop => prop.theme.screenSizes.lg}) {
    padding: var(--gutter-l);
  }
`

interface Props {
  children: JSX.Element[] | JSX.Element
}

const Section: React.ForwardRefRenderFunction<any, Props> = (
  { children, ...props }: Props,
  ref: any
) => {
  return (
    <SectionElement ref={ref} {...props}>
      {children}
    </SectionElement>
  )
}

export default React.forwardRef<any, Props>(Section)

import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const ListItemElement = styled.li<Props>`
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-column-gap: 15px;
  align-items: start;
  font-size: 20px;
  margin-bottom: 15px;
  color: ${props => (props.inverse ? "white" : props.theme.colors.text)};

  @media (${prop => prop.theme.screenSizes.sm}) {
    margin-bottom: 22px;
    span {
      opacity: 0.6;
    }
  }
`

const Img = styled(GatsbyImage)`
  width: 18px;
  height: 17px;
  margin-top: 4px;

  img {
    object-fit: contain !important;
  }
`

interface Props {
  children: JSX.Element[] | JSX.Element | string
  inverse?: boolean
}

const ListItem: React.FC<Props> = ({ children, inverse, ...props }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      tickIcon: file(relativePath: { eq: "other/tick.png" }) {
        childImageSharp {
          fluid(maxWidth: 35, maxHeight: 34, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ListItemElement inverse={inverse} {...props}>
      <Img
        fluid={data.tickIcon.childImageSharp.fluid}
        alt="Photo of Diprente film: Queen Sono"
      />
      <span>{children}</span>
    </ListItemElement>
  )
}

export default ListItem

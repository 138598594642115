import React, { useContext, useRef, useCallback } from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { ScrollContext } from "../../context/Scroll"
import { useEnterAnimation, useScrollAnimation } from "../../hooks/scroll"
import { TweenLite } from "gsap"

import Section from "../elements/Section"
import Title from "../elements/Title"
import ListItem from "../elements/ListItem"
import ButtonBase from "../elements/Button"
import DotsBase from "../elements/Dots"

const Container = styled(Section)`
  background-color: ${props => props.theme.colors.employeesBg};
  display: grid;
  grid-template-columns: 1fr;
  padding-right: 0;
  @media (${prop => prop.theme.screenSizes.md}) {
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
`

const Left = styled.div`
  z-index: 2;
  opacity: 0;
  transform: translate(-100px, 0px);
  /* grid-row: 1; */
  /* grid-column: 1; */
`

const Right = styled.div`
  grid-row: 1;
  grid-column: 1;
  opacity: 0;
  @media (${prop => prop.theme.screenSizes.md}) {
    grid-column: 2;
  }
`

const HeroContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 30% 10%;
  grid-template-rows: 1fr 0.1fr 0.5fr;
  /* opacity: 0.7; */
  margin-top: -115px;
  @media (${prop => prop.theme.screenSizes.md}) {
    margin-top: -22%;
    opacity: 1;
    padding-left: calc(var(--gutter-m) * 1.5);
    grid-row: 2;
  }
`

const HeroImage = styled(GatsbyImage)`
  grid-column: 1 / 3;
  grid-row: 1 / 3;
`

const Dots = styled(DotsBase)`
  grid-column: 2 / 4;
  grid-row: 2 / 4;
`

const Button = styled(ButtonBase)`
  margin-left: ${props => props.theme.listButtonOffset};
  margin-top: var(--gutter-l);
`

const Employees: React.FC = () => {
  const { scrollToSection } = useContext(ScrollContext)

  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero/employees-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 828, maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const heroContainerRef = useRef<any>(null)
  const contentContainerRef = useRef<any>(null)
  const heroImageRef = useRef<any>(null)
  const dotsRef = useRef<any>(null)

  const handleHeroParallax = useCallback(
    scrollPercentage => {
      const heroMaxTranslate = 15
      const dotsMaxTranslate = -40
      TweenLite.to(
        heroImageRef.current.imageRef.current.parentElement.parentElement,
        0.1,
        {
          y: `${(heroMaxTranslate * scrollPercentage) / 100}%`,
          ease: "none",
        }
      )
      TweenLite.to(dotsRef.current, 0.1, {
        y: `${(dotsMaxTranslate * scrollPercentage) / 100}%`,
        ease: "none",
      })
    },
    [heroImageRef]
  )

  useScrollAnimation(heroContainerRef, handleHeroParallax)
  useEnterAnimation(heroContainerRef, { opacity: 1 }, 70)
  useEnterAnimation(contentContainerRef, { opacity: 1, x: 0 }, 70)

  return (
    <Container>
      <Left ref={contentContainerRef}>
        <Title inverse>
          <span>
            Challenges facing
            <br /> employees.
          </span>
        </Title>
        <ul>
          <ListItem inverse>
            Debt is the key contributor to financial stress
          </ListItem>
          <ListItem inverse>
            Debt negatively affects physical and mental health
          </ListItem>
          <ListItem inverse>Employees have weak credit profiles</ListItem>
          <ListItem inverse>Most are over indebted or over-extended </ListItem>
          <ListItem inverse>Some are economically trapped</ListItem>
          <ListItem inverse>
            Access to financial products and services limited
          </ListItem>
          <ListItem inverse>Excessive market interest rates and fees</ListItem>
        </ul>
        <Button
          onClick={() => {
            if (scrollToSection) {
              scrollToSection("Contact us")
            }
          }}
        >
          Need help? Talk to us
        </Button>
      </Left>
      <Right ref={heroContainerRef}>
        <HeroContainer>
          <HeroImage
            ref={heroImageRef}
            fluid={data.hero.childImageSharp.fluid}
            alt="Loan Circle: Challenges facing employers image"
          />
          <Dots ref={dotsRef} />
        </HeroContainer>
      </Right>
    </Container>
  )
}

export default Employees

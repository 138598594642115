import React from "react"
import styled from "styled-components"

const ButtonElement = styled.button<Props>`
  background-color: ${props => props.theme.colors.buttonBg};
  font-size: 22px;
  line-height: 22px;
  color: white;
  transition: background-color ease 300ms;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 34px;
  padding-right: 34px;
  display: inline-block;
  flex: 0;
  outline: none;
  border: none;
  min-width: 270px;
  font-family: "Gilroy";
  font-weight: bold;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  min-height: 66px;

  &:hover {
    cursor: pointer;
    background-color: ${props =>
      !props.disabled && props.theme.colors.buttonBgHover};
  }
`

interface Props {
  children: JSX.Element[] | JSX.Element | string
  disabled?: boolean
  onClick?: () => void
}

const Button: React.ForwardRefRenderFunction<any, Props> = (
  { children, disabled, ...props }: Props,
  ref
) => {
  return (
    <ButtonElement ref={ref} disabled={disabled} {...props}>
      {children}
    </ButtonElement>
  )
}

export default React.forwardRef<any, Props>(Button)

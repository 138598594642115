import React from "react"
import styled from "styled-components"

const TitleElement = styled.h2<Props>`
  font-size: 30px;
  font-weight: bold;
  color: ${props =>
    props.inverse ? "white" : props.theme.colors.headingsText};
  margin-bottom: var(--gutter-m);
`

interface Props {
  children: JSX.Element[] | JSX.Element | string
  inverse?: boolean
}

const Title: React.ForwardRefRenderFunction<any, Props> = (
  { children, inverse, ...props }: Props,
  ref
) => {
  return (
    <TitleElement ref={ref} inverse={inverse} {...props}>
      {children}
    </TitleElement>
  )
}

export default React.forwardRef<any, Props>(Title)

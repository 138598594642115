import React, { useContext, useEffect, useRef } from "react"
import { ScrollContext } from "../../context/Scroll"
import styled, { keyframes } from "styled-components"
import lottie, { AnimationItem } from "lottie-web"

import Button from "../elements/Button"
import LogoLottie from "../../lotties/logo.json"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (${prop => prop.theme.screenSizes.md}) {
    grid-template-columns: 50vw 50vw;
    width: 100vw;
    min-height: 100vh;
  }
`

const Left = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: var(--gutter-s);
  padding-top: 150px;
  padding-bottom: var(--gutter-l);
  grid-row: 1;
  grid-column: 1;
  @media (${prop => prop.theme.screenSizes.md}) {
    padding: var(--gutter-l);
    padding-top: 100px;
  }
`

const Title = styled.h1`
  font-weight: bold;
  letter-spacing: -0.2vw;
  font-size: 14.4vw;
  line-height: 13.7vw;

  @media (${prop => prop.theme.screenSizes.md}) {
    letter-spacing: -0.2vw;
    font-size: 8.4vw;
    line-height: 7.7vw;
  }
`

const SubTitle = styled.h2`
  font-size: 3vw;
  max-width: 90%;
  margin-top: var(--gutter-s);
  margin-bottom: var(--gutter-m);

  @media (${prop => prop.theme.screenSizes.md}) {
    margin-bottom: var(--gutter-l);
    font-size: 1.6vw;
  }
`

const Right = styled.div`
  background-color: ${props => props.theme.colors.landingBg};
  grid-row: 1;
  grid-column: 1;
  pointer-events: none;
  opacity: 0.4;
  position: relative;
  z-index: -1;
  padding-top: 100px;
  @media (${prop => prop.theme.screenSizes.md}) {
    padding-top: 0px;
    opacity: 1;
    grid-column: 2;
  }
`

const ScaleAnimation = keyframes`
  0% {
    transform: scale(0.9)
  }
  50% {
    transform: scale(1)
  }
  100% {
    transform: scale(0.9)
  }
`

const LottieContainer = styled.div`
  width: 100%;
  max-width: 700px;
  height: 100%;
  max-height: 700px;

  @media (${prop => prop.theme.screenSizes.md}) {
    canvas {
      transform: scale(0.9);
      transform-origin: 50% 50% !important;
      animation: 7s ${ScaleAnimation} ease-in-out forwards infinite;
    }
  }
`

const Landing = () => {
  const containerRef = useRef<any>(null)
  const lottieRef = useRef<AnimationItem | null>(null)
  const lottieContainerRef = useRef<HTMLDivElement>(null)
  const { scrollToSection } = useContext(ScrollContext)

  useEffect(() => {
    const init = () => {
      if (lottieContainerRef.current) {
        lottieRef.current = lottie.loadAnimation({
          container: lottieContainerRef.current,
          renderer: "canvas",
          loop: true,
          autoplay: true,
          animationData: LogoLottie,
        })
        lottieRef.current.setSpeed(0.4)
      }
    }

    const destroy = () => {
      lottieRef.current?.destroy()
    }

    const resize = () => {
      destroy()
      init()
      const { height } = containerRef.current.getBoundingClientRect()
      if (window.scrollY > height) {
        lottieRef.current?.play()
      } else {
        lottieRef.current?.pause()
      }
    }

    const scroll = () => {
      const { height } = containerRef.current.getBoundingClientRect()
      if (window.scrollY > height) {
        lottieRef.current?.pause()
      } else {
        lottieRef.current?.play()
      }
    }

    init()
    window.addEventListener("resize", resize)
    window.addEventListener("scroll", scroll)

    return () => {
      window.removeEventListener("resize", resize)
      window.removeEventListener("scroll", scroll)
      destroy()
    }
  }, [])

  return (
    <Container ref={containerRef}>
      <Left>
        <Title>
          Financial
          <br /> wellness
          <br /> solutions.
        </Title>
        <SubTitle>
          We harness the power of technology to support employers empowering
          their employees.
        </SubTitle>
        <Button
          onClick={() => {
            if (scrollToSection) {
              scrollToSection("Benefits")
            }
          }}
        >
          Learn more
        </Button>
      </Left>
      <Right className="center-content">
        <LottieContainer ref={lottieContainerRef} />
      </Right>
    </Container>
  )
}

export default Landing

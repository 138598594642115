import React, { useContext, useEffect, useRef, useCallback } from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { ScrollContext } from "../../context/Scroll"
import { useEnterAnimation, useScrollAnimation } from "../../hooks/scroll"
import { TweenLite } from "gsap"

import ContactForm from "./ContactForm"
import Section from "../elements/Section"
import DotsBase from "../elements/Dots"
import TitleBase from "../elements/Title"

const Container = styled(Section)<{ ref: any }>`
  background-color: ${props => props.theme.colors.formBg};
`

const TopContainer = styled.div`
  display: grid;

  @media (${prop => prop.theme.screenSizes.md}) {
    grid-template-columns: 420px 1fr;
    grid-column-gap: 30px;
  }
`

const Title = styled(TitleBase)`
  font-size: 40px;
  margin-bottom: 8px;
`

const SubTitle = styled.p`
  font-size: 20px;
  opacity: 0.6;
  color: ${props => props.theme.colors.text};
  margin-bottom: var(--gutter-m);
`

const FormContainer = styled.div`
  /* grid-row: 1; */
  /* grid-column: 1; */
  opacity: 0;
  transform: translate(-100px, 0px);
  z-index: 2;
`

const HeroContainer = styled.div`
  opacity: 0;
  display: grid;
  width: 100%;
  max-width: 700px;
  grid-template-columns: 10% 25% 65%;
  grid-template-rows: 50% 9% 41%;
  justify-self: center;
  grid-row: 1;
  grid-column: 1;
  /* opacity: 0.6; */
  margin-top: -95px;
  @media (${prop => prop.theme.screenSizes.md}) {
    margin-top: 0px;
    grid-column: 2;
    margin-top: -200px;
  }
`

const HeroImage = styled(GatsbyImage)`
  grid-row: 1 / 3;
  grid-column: 2 / 4;

  img {
    object-fit: contain !important;
  }
`

const Dots = styled(DotsBase)`
  grid-row: 2 / 4;
  grid-column: 1 / 3;
`

const PartnersContainer = styled.div`
  opacity: 0;
  transform: translate(-100px, 0px);
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  @media (${prop => prop.theme.screenSizes.md}) {
    align-items: flex-end;
    margin-top: -50px;
  }
`

const PartnerLogosContainer = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: column;

  @media (${prop => prop.theme.screenSizes.md}) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
`

interface Dimensions {
  width: number
  height: number
}

const Logo = styled(GatsbyImage)<Dimensions>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin-top: 35px;

  img {
    object-fit: contain !important;
  }

  @media (${prop => prop.theme.screenSizes.md}) {
    margin-left: 3vw;
    margin-bottom: 0;
  }
`

const Contact: React.FC = () => {
  const { registerSection } = useContext(ScrollContext)
  const containerRef = useRef<any>(null)

  useEffect(() => {
    if (registerSection) {
      registerSection({ name: "Contact us", elementRef: containerRef })
    }
  }, [registerSection])

  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero/contact-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 671, maxHeight: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      creditGateway: file(
        relativePath: { eq: "logos/credit-gateway-logo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 523, maxHeight: 47, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      compuScan: file(relativePath: { eq: "logos/compuscan-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 410, maxHeight: 106, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      xds: file(relativePath: { eq: "logos/xds-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 131, maxHeight: 90, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transUnion: file(relativePath: { eq: "logos/transunion-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 315, maxHeight: 82, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const partnersRef = useRef<any>(null)
  const formRef = useRef<any>(null)
  const heroContainerRef = useRef<any>(null)
  const heroImageRef = useRef<any>(null)
  const dotsRef = useRef<any>(null)

  const handleHeroParallax = useCallback(
    scrollPercentage => {
      const heroMaxTranslate = 15
      const dotsMaxTranslate = -20
      TweenLite.to(
        heroImageRef.current.imageRef.current.parentElement.parentElement,
        0.1,
        {
          y: `${(heroMaxTranslate * scrollPercentage) / 100}%`,
          ease: "none",
        }
      )
      TweenLite.to(dotsRef.current, 0.1, {
        y: `${(dotsMaxTranslate * scrollPercentage) / 100}%`,
        ease: "none",
      })
    },
    [heroImageRef]
  )

  useScrollAnimation(heroContainerRef, handleHeroParallax)

  useEnterAnimation(partnersRef, { opacity: 1, x: 0 }, 70)
  useEnterAnimation(heroContainerRef, { opacity: 1 })
  useEnterAnimation(formRef, { opacity: 1, x: 0 })

  return (
    <Container ref={containerRef}>
      <TopContainer>
        <FormContainer ref={formRef}>
          <Title>
            <span>
              We want to <br />
              help your company.
            </span>
          </Title>
          <SubTitle>The coffee&apos;s on us.</SubTitle>
          <ContactForm />
        </FormContainer>

        <HeroContainer ref={heroContainerRef}>
          <HeroImage
            ref={heroImageRef}
            fluid={data.hero.childImageSharp.fluid}
            alt="Coffee Image"
          />
          <Dots ref={dotsRef} />
        </HeroContainer>
      </TopContainer>

      <PartnersContainer ref={partnersRef}>
        <Title>
          <span>
            Our <br />
            partners.
          </span>
        </Title>

        <PartnerLogosContainer>
          <Logo
            fluid={data.creditGateway.childImageSharp.fluid}
            alt="Credit Gateway Logo"
            width={523 / 2}
            height={47 / 2}
          />
          <Logo
            fluid={data.compuScan.childImageSharp.fluid}
            alt="CompuScan Logo"
            width={410 / 2}
            height={106 / 2}
          />
          <Logo
            fluid={data.xds.childImageSharp.fluid}
            alt="XDS Logo"
            width={131 / 2}
            height={90 / 2}
          />
          <Logo
            fluid={data.transUnion.childImageSharp.fluid}
            alt="Trans Union Logo"
            width={315 / 2}
            height={82 / 2}
          />
        </PartnerLogosContainer>
      </PartnersContainer>
    </Container>
  )
}

export default Contact

import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { ScrollContext } from "../../context/Scroll"
import { useEnterAnimation } from "../../hooks/scroll"

import TitleBase from "../elements/Title"
import Section from "../elements/Section"
import ButtonBase from "../elements/Button"

const Container = styled(Section)<{ ref: any }>`
  @media (${prop => prop.theme.screenSizes.md}) {
    margin-bottom: 200px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (${prop => prop.theme.screenSizes.md}) {
    flex-direction: row;
  }
`

const Quote = styled.p`
  color: ${props => props.theme.colors.quoteText};
  font-family: "Mogan";
  font-size: 20px;
  opacity: 0;
  transform: translate3d(100px, 0px, 0px);
  will-change: transform;

  @media (${prop => prop.theme.screenSizes.md}) {
    text-align: right;
    width: 100%;
    font-size: 30px;
    max-width: 500px;
  }
`

const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto auto;
  grid-row-gap: var(--gutter-m);
  grid-column-gap: var(--gutter-m);
  align-items: center;
  margin-top: var(--gutter-l);

  @media (${prop => prop.theme.screenSizes.sm}) {
    margin-top: 120px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-columns: 280px 300px;
    grid-row-gap: 120px;
    grid-column-gap: 25vw;
  }
  @media (${prop => prop.theme.screenSizes.md}) {
    padding-left: 9vw;
  }

  @media (${prop => prop.theme.screenSizes.lg}) {
    padding-left: 18vw;
  }
`

const ProductBlock = styled.div`
  opacity: 0;
  transform: translate3d(0px, 100px, 0px);
  will-change: transform;
`

const ProductImage = styled(GatsbyImage)`
  height: 150px;

  img {
    object-fit: contain !important;
    object-position: top left !important;
  }
`

const SubTitle = styled.h2`
  font-size: 25px;
  color: ${props => props.theme.colors.text};
  padding-left: 15px;
  margin: var(--gutter-s) 0px;
  font-weight: bold;
`

const Description = styled.p`
  font-size: 20px;
  color: ${props => props.theme.colors.text};
  opacity: 0.6;
  padding-left: 15px;
`

const RedirectLink = styled.a`
  font-size: 25px;
  color: ${props => props.theme.colors.buttonBg};
  padding-left: 15px;
  margin: var(--gutter-s) 0px;
  font-weight: bold;

  &:hover,
  &.active {
    cursor: pointer;
    color: ${props => props.theme.colors.employeesBg};
    &:before {
      width: 100%;
    }
  }
`

const Title = styled(TitleBase)`
  font-size: 40px;
  opacity: 0;
  transform: translate3d(-100px, 0px, 0px);
  will-change: transform;
`

const Button = styled(ButtonBase)`
  opacity: 0;
  transform: translate3d(0px, 100px, 0px);
  will-change: transform;
`
const ApplicationComponent = () => {
  return (
    <RedirectLink
      key="credit-link"
      href="https://loancircle.creditgateway.co.za"
    >
      I want to do this NOW!
    </RedirectLink>
  )
}

const Products = () => {
  const { registerSection, scrollToSection } = useContext(ScrollContext)
  const containerRef = useRef<any>(null)

  useEffect(() => {
    if (registerSection) {
      registerSection({ name: "Products", elementRef: containerRef })
    }
  }, [registerSection])

  const data = useStaticQuery(graphql`
    query {
      financialOverview: file(
        relativePath: { eq: "products/financial-overview-icon.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 470, maxHeight: 304, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      creditReport: file(
        relativePath: { eq: "products/credit-report-review-icon.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 522, maxHeight: 293, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      garnisheeRemoval: file(
        relativePath: { eq: "products/garnishee-removal-icon.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 386, maxHeight: 339, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      creditInsurance: file(
        relativePath: { eq: "products/credit-life-insurance-icon.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 411, maxHeight: 316, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      creditProducts: file(
        relativePath: { eq: "products/credit-products-icon.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 458, maxHeight: 307, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const titleRef = useRef<any>(null)
  const quoteRef = useRef<any>(null)
  const productBlock1Ref = useRef<any>(null)
  const productBlock2Ref = useRef<any>(null)
  const productBlock3Ref = useRef<any>(null)
  const productBlock4Ref = useRef<any>(null)
  const productBlock5Ref = useRef<any>(null)
  const productBlock6Ref = useRef<any>(null)

  useEnterAnimation(titleRef, { opacity: 1, x: 0 }, 70)
  useEnterAnimation(quoteRef, { opacity: 1, x: 0 }, 70)
  useEnterAnimation(productBlock1Ref, { opacity: 1, y: 0 }, 70)
  useEnterAnimation(productBlock2Ref, { opacity: 1, y: 0 }, 70)
  useEnterAnimation(productBlock3Ref, { opacity: 1, y: 0 }, 70)
  useEnterAnimation(productBlock4Ref, { opacity: 1, y: 0 }, 70)
  useEnterAnimation(productBlock5Ref, { opacity: 1, y: 0 }, 70)
  useEnterAnimation(productBlock6Ref, { opacity: 1, y: 0 }, 70)

  return (
    <Container ref={containerRef}>
      <TitleContainer>
        <Title ref={titleRef}>
          <span>
            Products and <br />
            solutions.
          </span>
        </Title>
        <Quote ref={quoteRef}>
          &rdquo;A loan should be the catalyst for change, not the goal. It
          should form part of a comprehensive solution executed by a
          multidisciplinary team of specialists.&ldquo;
        </Quote>
      </TitleContainer>

      <ProductsContainer>
        <ProductBlock ref={productBlock1Ref}>
          <ProductImage
            fluid={data.financialOverview.childImageSharp.fluid}
            alt="Loan Circle: Challenges facing employers image"
          />
          <SubTitle>
            Financial <br />
            overview
          </SubTitle>
          <Description>
            We assess and review your current financial situation. Depening on
            this, we will give you a roadmap for the future.
          </Description>
          <ApplicationComponent />
        </ProductBlock>

        <ProductBlock ref={productBlock2Ref}>
          <ProductImage
            fluid={data.creditReport.childImageSharp.fluid}
            alt="Loan Circle: Challenges facing employers image"
          />
          <SubTitle>
            Credit
            <br />
            report recon
          </SubTitle>
          <Description>
            We go into every detail to find cases of reckless lending, reverse
            unsubstantiated admin orders and rescind judgments.
          </Description>
          <ApplicationComponent />
        </ProductBlock>

        <ProductBlock ref={productBlock3Ref}>
          <ProductImage
            fluid={data.garnisheeRemoval.childImageSharp.fluid}
            alt="Loan Circle: Challenges facing employers image"
          />
          <SubTitle>
            Garnishee <br />
            removals
          </SubTitle>
          <Description>
            We ensure the right steps are taken to remove all illegal
            garnishees.
          </Description>
          <ApplicationComponent />
        </ProductBlock>

        <ProductBlock ref={productBlock4Ref}>
          <ProductImage
            fluid={data.creditInsurance.childImageSharp.fluid}
            alt="Loan Circle: Challenges facing employers image"
          />
          <SubTitle>
            Credit
            <br />
            life insurance
          </SubTitle>
          <Description>
            We assist with consolidating all policies into one affordable
            payment.
          </Description>
          <ApplicationComponent />
        </ProductBlock>

        <ProductBlock ref={productBlock5Ref}>
          <ProductImage
            fluid={data.creditProducts.childImageSharp.fluid}
            alt="Loan Circle: Challenges facing employers image"
          />
          <SubTitle>
            Credit <br />
            products
          </SubTitle>
          <Description>
            We assist with credit to remove adverse listings and to help clear
            credit records.
          </Description>
          <ApplicationComponent />
        </ProductBlock>

        <Button
          ref={productBlock6Ref}
          onClick={() => {
            if (scrollToSection) {
              scrollToSection("Contact us")
            }
          }}
        >
          Need help? Talk to us
        </Button>
      </ProductsContainer>
    </Container>
  )
}

export default Products

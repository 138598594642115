import React from "react"
import Landing from "./Landing"
import Employers from "./Employers"
import Employees from "./Employees"
import Products from "./Products"
import Contact from "./Contact"

const Home = () => {
  return (
    <>
      <Landing />
      <Employers />
      <Employees />
      <Products />
      <Contact />
    </>
  )
}

export default Home

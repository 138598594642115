import { Formik } from "formik"
import React, { useState } from "react"
import styled from "styled-components"
import * as Yup from "yup"
import jsonp from "jsonp"
import toQueryString from "to-querystring"

import Input from "../elements/Input"
import ButtonBase from "../elements/Button"

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const Result = styled.div<{ type: string }>`
  text-align: center;
  font-size: 12pt;
  margin: 10px 0;
  color: ${props =>
    props.type === "error" ? "#f38c72" : props.theme.colors.text};
`

const Icon = styled.svg`
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: 0 8px;
  margin-bottom: 3px;
`

const Button = styled(ButtonBase)`
  @media (${prop => prop.theme.screenSizes.md}) {
    float: right;
  }
`

const validationSchema = Yup.object({
  NAME: Yup.string().required("Please enter your name"),
  COMPANY: Yup.string().required("Please enter your company name"),
  EMAIL: Yup.string()
    .email("Enter a valid e-mail address")
    .required("Please enter your e-mail address"),
  PHONE: Yup.string()
    .required("Please enter a cellphone number")
    .matches(phoneRegExp, "Enter a valid cellphone number")
    .test(
      "len",
      "Enter a valid cellphone number",
      (val: string) => !val || val.length === 10
    )
    .required("Please enter your cellphone number"),
})

const MAILCHIMP_BASE_URL =
  "https://loancircle.us19.list-manage.com/subscribe/post-json?u=3713ee91c786fd7c4dae2aff3&amp;id=e2f93e14b9"

const SignUpForm = () => {
  const [error, setError] = useState<string | null>(null)
  const [done, setDone] = useState(false)

  // eslint-disable-next-line @typescript-eslint/require-await
  const submit = async (values: any): Promise<void> => {
    const params = toQueryString(values)
    const url = MAILCHIMP_BASE_URL + "&" + params
    jsonp(
      url,
      {
        param: "c",
      },
      (err: any, data: any) => {
        if (err) {
          setError("An error has occured. Please try again later.")
        } else if (data.result !== "success") {
          if (!data.msg.includes("already subscribed")) {
            setError("An error has occured. Please try again later.")
          }
        }
        setDone(true)
      }
    )
  }

  return (
    <Formik
      initialValues={{
        NAME: "",
        COMPANY: "",
        EMAIL: "",
        PHONE: "",
      }}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ isValid, handleSubmit, isSubmitting }) => (
        <>
          <Input placeholder="Name" name="NAME" />
          <Input placeholder="Company" name="COMPANY" />
          <Input placeholder="Email address" name="EMAIL" type="email" />
          <Input placeholder="Cellphone number" name="PHONE" type="tel" />
          {!done ? (
            <Button disabled={!isValid || isSubmitting} onClick={handleSubmit}>
              Send
            </Button>
          ) : error ? (
            <Result type="error">
              <Icon viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z"
                />
              </Icon>
              {error}
            </Result>
          ) : (
            <Result type="success">
              <Icon viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"
                />
              </Icon>
              Thank you! You will hear from us shortly.
            </Result>
          )}
        </>
      )}
    </Formik>
  )
}

export default SignUpForm

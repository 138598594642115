import { useEffect, useCallback, useRef } from "react"
import { TweenLite } from "gsap"

export const useEnterAnimation = (
  elementRef: any,
  properties: Record<string, any>,
  offsetFromTop = 50,
  duration = 0.75
): void => {
  const frameId = useRef<number>(0)

  const handleScroll = useCallback(() => {
    const topOffset = (window.innerHeight * offsetFromTop) / 100
    const { y } = elementRef.current.getBoundingClientRect()
    const topBounds = y - topOffset

    const isInViewPort = topBounds < 0

    if (isInViewPort) {
      TweenLite.to(elementRef.current, {
        duration,
        ease: "none",
        rotate: 0,
        ...properties,
      })
    }
  }, [duration, elementRef, offsetFromTop, properties])

  const onScroll = useCallback(() => {
    frameId.current = window.requestAnimationFrame(handleScroll)
  }, [handleScroll])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)

    return (): void => {
      window.removeEventListener("scroll", onScroll)
      window.cancelAnimationFrame(frameId.current)
    }
  }, [onScroll])
}

export const useScrollAnimation = (
  elementRef: any,
  callback: (scrollPercentage: number) => void
): void => {
  const frameId = useRef<number>(0)
  const startY = useRef(0)
  const endY = useRef(0)

  const handleScroll = useCallback(() => {
    if (window.innerWidth > 768) {
      if (elementRef.current) {
        const windowHeight = window.innerHeight
        const scrollPosition = window.scrollY + windowHeight
        const scrollPercentage = Math.min(
          Math.max(
            ((scrollPosition - startY.current) * 100) /
              (endY.current - startY.current),
            0
          ),
          100
        )
        if (callback) {
          callback(scrollPercentage)
        }
      }
    }
  }, [callback])

  const onScroll = useCallback(() => {
    frameId.current = window.requestAnimationFrame(handleScroll)
  }, [handleScroll])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)

    if (elementRef.current) {
      const { y, height } = elementRef.current.getBoundingClientRect()
      startY.current = y
      endY.current = y + height + window.innerHeight
    }

    return (): void => {
      window.removeEventListener("scroll", onScroll)
      window.cancelAnimationFrame(frameId.current)
    }
  }, [elementRef, onScroll])
}

import React, { useContext, useEffect, useRef, useCallback } from "react"
import styled from "styled-components"
import { ScrollContext } from "../../context/Scroll"
import { useEnterAnimation, useScrollAnimation } from "../../hooks/scroll"
import { TweenLite } from "gsap"

import Section from "../elements/Section"
import Title from "../elements/Title"
import ListItem from "../elements/ListItem"
import ButtonBase from "../elements/Button"
import DotsBase from "../elements/Dots"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const Container = styled(Section)`
  display: grid;
  @media (${prop => prop.theme.screenSizes.md}) {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5%;
  }
`

const MainTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  max-width: 90%;
  margin-bottom: var(--gutter-l);
  opacity: 0;
  transform: translate3d(-100px, 0px, 0px);

  @media (${prop => prop.theme.screenSizes.md}) {
    font-size: 40px;
    margin-bottom: 25%;
    margin: var(--gutter-l) 0px;
  }
`

const HeroContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 15% 25%;
  z-index: -1;
  /* overflow: hidden; */
  opacity: 0;
  /* opacity: 0.4; */
  /* margin-bottom: -400px; */
  @media (${prop => prop.theme.screenSizes.md}) {
    margin-bottom: 0px;
    grid-row: 2;
    padding-right: var(--gutter-m);
  }
`

const HeroImage = styled(GatsbyImage)`
  grid-column: 1 / 3;
  grid-row: 1;

  img {
    object-fit: contain !important;
    object-position: top right !important;
  }
`

const Dots = styled(DotsBase)`
  grid-column: 2 / 4;
  grid-row: 1;
`

const ContentContainer = styled.div`
  opacity: 0;
  transform: translate3d(100px, 0px, 0px);
  @media (${prop => prop.theme.screenSizes.md}) {
    padding-left: var(--gutter-l);
    grid-row: 2;
    padding-top: 10%;
    grid-column: 2;
  }
`

const Button = styled(ButtonBase)`
  margin-left: ${props => props.theme.listButtonOffset};
  margin-top: var(--gutter-l);
`

const Employers: React.FC = () => {
  const { registerSection, scrollToSection } = useContext(ScrollContext)
  const containerRef = useRef<any>(null)
  const heroContainerRef = useRef<any>(null)
  const heroImageRef = useRef<any>(null)
  const dotsRef = useRef<any>(null)
  const mainTitleRef = useRef<any>(null)
  const contentContainerRef = useRef<any>(null)

  useEffect(() => {
    if (registerSection) {
      registerSection({ name: "Benefits", elementRef: containerRef })
    }
  }, [registerSection])

  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero/employers-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 606, maxHeight: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleHeroParallax = useCallback(
    scrollPercentage => {
      const heroMaxTranslate = 15
      const dotsMaxTranslate = 40
      TweenLite.to(
        heroImageRef.current.imageRef.current.parentElement.parentElement,
        0.1,
        {
          y: `${(heroMaxTranslate * scrollPercentage) / 100}%`,
          ease: "none",
        }
      )
      TweenLite.to(dotsRef.current, 0.1, {
        y: `${(dotsMaxTranslate * scrollPercentage) / 100}%`,
        ease: "none",
      })
    },
    [heroImageRef]
  )

  useEnterAnimation(mainTitleRef, { opacity: 1, x: 0 })
  useEnterAnimation(heroContainerRef, { opacity: 1 })
  useEnterAnimation(contentContainerRef, { opacity: 1, x: 0 })
  useScrollAnimation(heroContainerRef, handleHeroParallax)

  return (
    <Container ref={containerRef}>
      <MainTitle ref={mainTitleRef}>
        Keeping your employees financially healthy has become more and more
        important in uncertain times. Let us show you how.
      </MainTitle>
      <HeroContainer ref={heroContainerRef}>
        <HeroImage
          ref={heroImageRef}
          fluid={data.hero.childImageSharp.fluid}
          alt="Loan Circle: Challenges facing employers image"
        />
        <Dots ref={dotsRef} />
      </HeroContainer>
      <ContentContainer ref={contentContainerRef}>
        <Title>
          <span>
            Challenges facing
            <br /> employers.
          </span>
        </Title>
        <ul>
          <ListItem>High financial cost of increased absenteeism</ListItem>
          <ListItem>
            Recruitment, retention, incentives and staff morale
          </ListItem>
          <ListItem>Socio Economic challenges faced by employees</ListItem>
          <ListItem>Theft and fraud increases</ListItem>
          <ListItem>Administrative burden on payroll</ListItem>
          <ListItem>Economic empowerment compliance</ListItem>
          <ListItem>NCA / NCR compliance</ListItem>
        </ul>
        <Button
          onClick={() => {
            if (scrollToSection) {
              scrollToSection("Contact us")
            }
          }}
        >
          Need help? Talk to us
        </Button>
      </ContentContainer>
    </Container>
  )
}

export default Employers

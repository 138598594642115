import { useField, FieldInputProps } from "formik"
import React from "react"
import styled from "styled-components"

const StyledInput = styled.input`
  min-height: 60px;
  border-width: 1px;
  border-color: #54e2da;
  border-style: solid;
  background-color: ${props => props.theme.colors.formBg};
  width: 100%;
  border-radius: 5px;
  margin-bottom: 14px;
  font-size: 18px;
  font-family: "Gilroy";
  font-weight: normal;
  padding: 20px 26px;
  outline: none;
  &::placeholder {
    color: black;
    opacity: 1;
  }

  &:focus {
    border-color: rgb(96, 189, 236);
  }
`

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

const InputError = styled.div`
  position: absolute;
  bottom: 0;
  right: 20px;
  background-color: ${props => props.theme.colors.formBg};
  z-index: 30;
  padding: 8px;
  color: #f38c72;
  font-size: 10pt;
  transition: opacity 300ms;
`

interface Props {
  placeholder: string
  type?: string
  name: string
}

const Input: React.FC<Props> = ({ ...props }: Props) => {
  const [field, meta] = useField(props)
  return (
    <InputWrapper>
      <StyledInput {...field} {...props} />
      <InputError
        style={{
          opacity: meta.touched && meta.error ? 1 : 0,
        }}
      >
        {meta.error}
      </InputError>
    </InputWrapper>
  )
}

export default Input

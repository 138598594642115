import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const DotsContainer = styled.div``

const Img = styled(GatsbyImage)`
  img {
    object-fit: contain !important;
  }
`

const Dots: React.ForwardRefRenderFunction<any, {}> = ({ ...props }, ref) => {
  const data = useStaticQuery(graphql`
    query {
      tickIcon: file(relativePath: { eq: "other/dots.png" }) {
        childImageSharp {
          fluid(maxWidth: 408, maxHeight: 478, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <DotsContainer ref={ref} {...props}>
      <Img
        fluid={data.tickIcon.childImageSharp.fluid}
        alt="Photo of Diprente film: Queen Sono"
      />
    </DotsContainer>
  )
}

export default React.forwardRef<any, {}>(Dots)
